<template>
  <div class="history-lpr">
    <div class="search-bar">
      <SearchBarLpr />
    </div>
    <div class="history-lpr-map">
      <GmapMarkerPath :eventList="historyEventList" :focusIndex="focusEventIndex" />
      <div class="event-board">
        <EventBoard v-show="historyEventList.length > 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'HistoryLpr',
  components: {
    SearchBarLpr: () => import('@/components/History/SearchBarLpr.vue'),
    GmapMarkerPath: () => import('@/components/History/HistoryContent/GmapMarkerPath.vue'),
    EventBoard: () => import('@/components/History/HistoryContent/EventBoard.vue'), 
    
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('history', ['historyEventList', 'focusEventIndex']),
  },
  mounted() {
    this.updateHistoryEventModalType('lpr')
  },
  beforeDestroy() {
    // 離開頁面清空搜尋資料
    this.resetHistoryData()
    this.$store.commit('video/resetState')
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalType']),
    ...mapActions('history', ['resetHistoryData']),
  }
}
</script>

<style lang="scss" scoped>
.history-lpr {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .search-bar {
    width: 100%;
    height: 133px;
  }

  .history-lpr-map {
    width: 100%;
    height: calc(100% - 133px);
    position: relative;
    .event-board {
      position: absolute;
      top: 16px;
      right: 16px;
      bottom: 16px;
    }
  }
}
</style>